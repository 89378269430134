.menu__subsection-triangle {
  z-index: 4;
  position: relative;
  margin: calc(-1 * var(--small-mid-gap)) auto;
  width: 0;
  height: 0;
  border-width: var(--small-mid-gap);
  border-top-style: none;
  border-style: solid;
  border-color: transparent transparent var(--white);
}

.menu__subsection-container {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--medium-grey);
  overflow: scroll;
  overscroll-behavior: contain;

  .menu__subsection {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 4;
    transform: translateX(-50%);
    background-color: var(--white);
    padding: var(--mid-gap) var(--big-gap);
    border-radius: 20px;
    margin-top: 170px;
    margin-bottom: 50px;
    max-width: 1000px;
    min-width: 400px;
    overflow: hidden;

    .subsection__header {
      font-family: var(--accent-font-family);
      font-size: 36px;
      font-weight: normal;
      font-style: italic;
      text-transform: none;
      color: var(--orange);
      text-align: center;
      margin: var(--minimum-gap) 0 var(--big-mid-gap);

      @media (max-width: 480px) {
        margin-bottom: var(--big-small-gap);
      }
    }

    .subsection__columns-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      max-width: 400px;
      margin: 0 auto;
    }

    .subsection__cards-container {
      width: 1000px;
      display: grid;
      grid-template-columns: repeat(3, minmax(100px, 1fr));
      grid-gap: var(--small-mid-gap);
      grid-auto-flow: dense;
      padding: var(--small-mid-gap) 0 var(--mid-gap);

      @media (max-width: 1024px) {
        grid-template-columns: auto;
        max-width: 320px;
        width: auto;
      }

      .subsection__card {
        grid-column: span 1;
        grid-row: span 1;
        border-radius: var(--border-radius);
        box-shadow: var(--medium-box-shadow);
        padding: var(--small-gap);

        @media (max-width: 1024px) {
          grid-area: auto !important;
          max-width: 380px;
        }

        .subsection__card-order-info {
          text-align: center;

          ul {
            display: flex;
            list-style-type: none;
            color: var(--white);
            width: 340px;
            padding: 0;

            @media (max-width: 1024px) {
              display: none;
            }

            li {
              position: relative;
              display: inline-block;
              flex-basis: 100%;
              font-size: var(--minimum-font-size);
              padding: var(--minimum-gap);
              color: var(--dark-grey);

              &:nth-of-type(2),
              &:nth-of-type(2) span {
                background-color: #f5f4f4;
              }

              &:nth-of-type(3),
              &:nth-of-type(3) span {
                background-color: #eaeaea;
              }

              &:nth-of-type(4),
              &:nth-of-type(4) span {
                background-color: #e2e2e2;
              }

              .subsection__card-order-steps-finished {
                display: block;
                font-size: var(--accent-size);
                line-height: 16px;
                margin: var(--minimum-gap) auto 0;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                border: 1px solid var(--dark-grey);
                font-size: var(--minimum-font-size);
              }

              .subsection__card-order-steps-triangle {
                position: absolute;
                width: 10px;
                height: 10px;
                top: 10px;
                z-index: 1;
                right: 0;
                transform: rotate(45deg) translateX(50%);
              }

              &:first-of-type {
                border-radius: var(--border-medium-radius) 0 0 var(--border-medium-radius);
              }

              &:last-of-type {
                border-radius: 0 var(--border-medium-radius) var(--border-medium-radius) 0;
              }

              &.finished {
                color: var(--white);

                span {
                  border-color: var(--white);
                }

                &:nth-of-type(1),
                &:nth-of-type(1) span {
                  background-color: #8cd5ff;
                }

                &:nth-of-type(2),
                &:nth-of-type(2) span {
                  background-color: #80c7ff;
                }

                &:nth-of-type(3),
                &:nth-of-type(3) span {
                  background-color: #57b7ff;
                }

                &:nth-of-type(4),
                &:nth-of-type(4) span {
                  background-color: #2facff;
                }
              }
            }
          }
        }

        .subsection__card-order-history {
          list-style-type: none;
          font-size: var(--minimum-font-size);
          font-weight: 300;
          margin-right: var(--small-gap);

          li {
            margin-bottom: var(--minimum-gap);
            white-space: nowrap;
          }

          span:first-of-type {
            display: inline-block;
            width: 100px;
            color: var(--dark);
          }

          .history_grey span:last-of-type {
            color: var(--grey);
          }

          .history_blue span:last-of-type {
            color: var(--lighter-blue);
          }

          .history_green span:last-of-type {
            color: var(--green);
          }
        }

        &_inline {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 813px) {
            display: block;
          }

          h3,
          p {
            margin-top: 0;
            color: var(--light-blue);
            font-weight: 500;
          }

          p {
            font-size: var(--minimum-font-size);
            line-height: 1.43;
            margin: 0 0 var(--small-gap);
          }

          .subsection__card-icon {
            align-self: flex-start;
          }
        }

        &_vertical {
          display: flex;
          flex-direction: column;
        }

        .subsection__card-products {
          display: flex;
          overflow: auto;
          justify-content: space-between;
          align-items: baseline;

          .subsection__card-product {
            min-width: 60px;
            text-align: center;
            font-size: 10.8px;

            img {
              max-width: 40px;
              max-height: 50px;
            }
          }
        }

        .subsection__card-header {
          display: block;

          .subsection__card-header-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .subsection__card-header-icon {
            width: 64px;
            height: 64px;
          }

          span {
            font-family: var(--accent-font-family);
            font-size: 72px;
            font-style: italic;
            color: var(--orange);

            &:last-child {
              flex-grow: 1;
              text-align: center;
            }
          }

          h3 {
            font-size: var(--h3-size);
            font-weight: 400;
            color: var(--orange);
            text-align: center;
            white-space: nowrap;
            margin: 0;
          }
        }

        .subsection__card-caption {
          display: block;
          margin: 0;
          font-weight: 500;
          font-size: 20px;
          text-align: center;
          text-transform: uppercase;
          color: var(--light-blue);

          .subsection__card-caption_accent {
            position: relative;
            display: block;
            font-size: 64px;
            font-weight: normal;
            font-family: var(--accent-font-family);

            img {
              position: absolute;
              right: var(--small-gap);
              transform: translateY(-50%);
              top: 50%;
              right: -15%;
            }
          }

          &.vertical-caption {
            flex: 1;
            display: flex;
            align-items: center;
            flex-direction: column;
          }
        }

        .subsection__card-header:not(:last-child),
        .subsection__card-caption:not(:last-child) {
          border-bottom: var(--border-thin-width) solid var(--light-grey);
          padding-bottom: var(--small-gap);
          margin-bottom: var(--small-gap);
        }

        .subsection__card-caption_padded {
          padding: var(--mid-gap) 0;
          font-size: var(--h3-size);
          text-transform: none;
        }
      }
    }

    .subsection__close {
      cursor: pointer;
      position: absolute;
      right: var(--mid-gap);
      top: var(--mid-gap);
      width: var(--close-icon-size);
      height: var(--close-icon-size);

      @media (min-width: 481px) and (max-width: 813px) {
        right: 6px;
        top: 6px;
      }

      @media (max-width: 480px) {
        right: 6px;
        top: 6px;
        box-shadow: var(--mobile-medium-box-shadow);
        width: var(--mobile-close-icon-size);
        height: var(--mobile-close-icon-size);

        &:before,
        &:after {
          height: calc(var(--mobile-close-icon-size) - var(--small-mid-gap));
        }
      }

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        content: '';
        background-color: var(--grey);
        height: calc(var(--close-icon-size) - var(--small-mid-gap));
        width: var(--border-width);
      }

      &:after {
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      }
    }

    @media (min-width: 481px) and (max-width: 713px) {
      margin-top: 60px;
      width: 96%;
      padding: 5px;
      border-radius: 5px;
      min-width: 481px;

      &.no-padding {
        width: unset;
        min-width: 400px;
      }
    }

    @media (max-width: 480px) {
      margin-top: 60px;
      width: 96%;
      padding: 5px;
      border-radius: 10px;
      min-width: 320px;

      &.no-padding {
        width: unset;
        min-width: 400px;
      }

      .subsection__header,
      .subsection__simple-card {
        font-size: var(--big-font-size);
      }
    }
  }

  .menu__subsection-container-outer {
    z-index: 3;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.success-login {
  display: block;
  height: 540px;
  color: #7cb03d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 480px) {
    // min-width: 100%;
    height: 100%;
  }

  img {
    width: 36px;
    margin-right: 10px;
    margin-bottom: -2px;

    @media (max-width: 480px) {
      width: 20px;
      margin-bottom: -1px;
    }
  }

  opacity: 1;
  transition: visibility 1s,
  opacity .7s ease-in;

  &.hidden {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
