.catalog {
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--item-width));
  grid-gap: var(--small-mid-gap);
  padding-bottom: var(--big-mid-gap);
  justify-content: center;
  position: relative;

  &.announcements {
    padding-top: var(--big-mid-gap);

    @media (max-width: 480px) {
      padding-top: 5px;
    }
  }

  @media (max-width: 480px) {
    padding: var(--minimum-gap);

    .content__catalog & {
      grid-template-columns: 1fr;
      grid-gap: 5px;
      padding: 0;

      &__item {
        grid-template-columns: 1fr;
      }
    }

    .content__load-more {
      grid-template-columns: 1fr;
      width: auto;
      background-size: 100px;
      height: 170px;
    }
  }

  @media (min-width: 481px) and (max-width: 713px) {
    padding: var(--minimum-gap);
  }

  &__announcement {
    position: relative;
    overflow: hidden;
    display: grid;
    justify-items: center;
    text-align: center;
    grid-gap: var(--minimum-gap);
    background-color: var(--white);
    border-radius: var(--border-radius);
    width: 290px;
    min-width: 290px;
    height: 290px;
    margin: 0 auto;

    &.filled {
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
    }

    &-image {
      width: 180px;
      height: 180px;
      overflow: hidden;
      position: relative;
      grid-row: 1 / 1;
      border-radius: 100px;
      background-repeat: no-repeat;
      background-position: center center;
      //transform: translateY(-50%) translateX(-50%);
      background-size: cover;

      .loading & {
        background-color: var(--light-grey);
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        flex-direction: column;
        justify-content: center;
      }
    }

    &-header {
      font-weight: 700;
      font-size: var(--h3-size);
      line-height: 23px;
      color: var(--dark);
    }

    &-discription {
      font-size: var(--minimum-font-size);
      line-height: 20px;
      font-weight: 400;
      color: var(--dark);
    }

    &-button {
      display: flex;
      text-decoration: none;
      width: 180px;
      height: 40px;
      border-radius: 25px;
      border: var(--border-small-width) solid var(--blue);
      margin-bottom: 10px;
      font-size: var(--big-mid-font-size);
      line-height: 26px;
      color: var(--blue);
      justify-content: center;
      align-self: flex-end;

      span {
        display: block;
        align-self: center;
      }
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-rows: 150px 16px 50px 10px 35px auto;
    grid-template-areas: "image" "category" "title" "discount" "price" "counter";
    grid-gap: var(--minimum-gap);
    background-color: var(--white);
    border-radius: var(--border-radius);
    line-height: 14px;
    height: 345px;

    &-category {
      font-size: var(--thick-font-size);
      color: var(--grey);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 4px;
    }

    &-title {
      color: var(--main-text-color);
      font-size: var(--base-font-size);
      line-height: 24px;
      grid-area: title;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-props {
      font-size: var(--thick-font-size);
      color: var(--grey);
      font-weight: 400;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-price {
      font-size: 24px;
      color: var(--blue);
      grid-area: price;
      line-height: 32px;
      padding: 2px 2px;

      &.red {
        color: var(--week-red);
      }

      @media (max-width: 480px) {
        font-size: var(--mid-font-size);
      }

      &.blue {
        height: 20px;
        color: var(--crimson);
        font-weight: bold;
        font-size: var(--big-font-size);
        font-family: var(--accent-font-family);
        font-weight: bold;
      }
    }

    &-old {
      grid-area: discount;
      font-size: var(--thick-font-size);
      margin-left: 2px;
      line-height: 14px;
      color: var(--grey);

      &-price {
        text-decoration: line-through;
      }

      &-discount {
        padding-left: 8px;
        color: var(--week-red);
      }
    }

    &-image {
      grid-row: 1 / 1;
      width: 100%;
      height: 150px;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        max-height: 100%;
        max-width: 225px;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
      }

      @media (max-width: 480px) {
        height: 100px;
      }

      .loading & {
        background-color: var(--light-grey);
        display: flex;
        width: 190px;
        height: 150px;
        border-radius: 10px;
        flex-direction: column;
        justify-content: center;
      }
    }

    &-gift {
      width: 132px;
      height: 24px;
      background-color: var(--crimson);
      border-radius: 5px;

      &-text {
        display: block;
        margin-top: 4px;
        font-family: var(--accent-font-family);
        font-weight: bold;
        color: var(--white);
        font-size: var(--minimum-font-size);
      }
    }

    &-discount {
      position: absolute;
      left: 5px;
      top: 5px;
      color: var(--white);
      z-index: 0;

      &-text {
        white-space: nowrap;
        position: absolute;
        z-index: 2;
        font-weight: 700;
        font-size: 12px;
        min-width: 52px;
        height: 16px;
        line-height: 20px;
        border-radius: var(--border-small-radius);
        overflow: hidden;
        padding: 3px 4px 5px 4px;

        &.percent {
          background-color: var(--crimson);
        }

        &.takeoff {
          background-color: var(--crimson);
        }
      }

      &:after {
        content: '';
        position: absolute;
        //top: -60px;
        //left: -60px;
        //transform: rotate(-45deg);
        //border-left: 60px solid transparent;
        //border-right: 60px solid transparent;
        //border-bottom: 60px solid var(--light-blue);
        //width: 120px;
        //height: 120px;
        box-sizing: border-box;
        z-index: 1;
      }
    }

    &-ended {
      position: absolute;
      left: 5px;
      top: 5px;
      color: var(--white);
      z-index: 0;

      &-text {
        white-space: nowrap;
        position: absolute;
        z-index: 2;
        font-weight: 700;
        font-size: 12px;
        border-radius: var(--border-small-radius);
        overflow: hidden;
        padding: 2px 4px;
        background-color: var(--orange);
      }
    }

    &-add-to-favorites {
      position: absolute;
      cursor: pointer;
      right: 3px;
      top: 3px;
      background-color: transparent;
      background-image: url("../../assets/images/icons/black-hearth.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 18px;
      height: 16px;
      transition: all 0.3s ease;
      text-decoration: none;
      color: var(--dark);
      font-weight: 900;
      font-size: var(--mid-font-size);
      display: grid;
      align-items: center;
      border: none;
      outline: none;

      &.active {
        background-image: url("../../assets/images/icons/filled-hearth.svg");
      }
    }

    .form__counter.button {
      width: 100%;
      grid-template-columns: 100%;
      visibility: visible;
    }

    &-placeholders {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      div {
        background-color: var(--light-grey);
        height: 15px;
        width: 100%;
        border-radius: 8px;
        margin-top: 4px;
      }

      &.accordion div {
        width: 90%;
        margin-left: 10px;

        @media (max-width: 480px) {
          margin-left: 2px;
        }
      }

      &.bold div {
        height: 28px;
      }

      &.announcement div {
        height: 40px;
      }

      &-title {
        height: 18px;
        width: 90%;

        div {
          margin-top: 0px;
          height: 100%;
        }
      }

      &-price {
        height: 10px;
        width: 20%;

        div {
          margin-top: 0px;
          height: 100%;
        }
      }

      &-nav {
        height: 40px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div {
          margin-top: 0px;
          height: 100%;
          width: 70%;
        }

        span {
          background-color: var(--light-grey);
          height: 100%;
          width: 20%;
          border-radius: 8px;
        }
      }
    }

    &.loading {
      justify-content: center;

      &-image {
        background-color: var(--grey);
      }
    }

    &.search {
      width: 100%;

      .catalog__item-add-to-favorites {
        display: none;
      }

      .form__counter.button {
        width: 100%;
        grid-template-columns: 100%;
      }
    }
  }
}

.page__catalog {
  .catalog {
    @media (max-width: 480px) {
      margin-top: 20px;
      grid-gap: 20px;
    }
  }

  &.content {
    display: grid;
    grid-gap: var(--small-mid-gap);
    grid-template-areas:
      "breadcrumbs breadcrumbs"
      "left center"
      "arrivals arrivals"
      "suggested suggested";
    grid-template-columns: auto 1fr;

    @media (max-width: 1024px) {
      grid-template-areas:
        "breadcrumbs"
        "left"
        "center"
        "arrivals"
        "suggested";
      grid-template-columns: auto;
      padding: 10px;
    }

    section:not(.catalog__view):first-of-type {
      grid-area: arrivals;
      grid-template-columns: 2fr;
    }

    section:not(.catalog__view) {
      grid-area: suggested;
      grid-template-columns: 2fr;

      @media (max-width: 480px) {
        display: none;
      }
    }
  }
}

.discount {
  position: relative;
  overflow: hidden;
  display: grid;
  justify-items: center;
  text-align: center;
  grid-auto-rows: min-content;
  grid-gap: var(--minimum-gap);
  margin-left: var(--minimum-gap);
}

.swiper-slide.discount-container {
  width: auto !important;
}
