.form {
  &__input {
    display: block;
    border: var(--border-thin-width) solid var(--grey);
    height: 22px;
    border-radius: var(--border-radius);
    margin: 0;
    padding: 0 var(--small-gap);
    box-sizing: border-box;
    outline: none;
    font-size: var(--minimum-font-size);
    color: var(--dark-grey);

    &.card {
      height: 48px;
      font-size: var(--base-font-size);
      font-weight: 500;
      line-height: 24px;
      color: var(--main-text-color);
      border-radius: var(--border-width);
      background-color: var(--slightly-grey);
      border: none;
      width: 87px;
    }

    &--active {
      border-color: var(--orange);
    }

    &--wide {
      width: 80%;
    }

    &--centered {
      text-align: center;
    }

    &--full-width {
      width: 100%;
    }

    &--big {
      font-size: 16px;
    }

    &:focus {
      border-color: var(--blue);
      box-shadow: 0 0 1px var(--blue);
    }

    @media (max-width: 713px) {
      height: var(--mobile-form-input-height);
      font-size: var(--mobile-form-input-font-size);
      border-radius: 5px;
    }
  }

  &__counter {
    &-container {
      position: absolute;
      display: flex;
      width: 100%;
      height: 48px;
      bottom: 0;
      justify-items: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.2);

      @media (max-width: 480px) {
        visibility: hidden;
      }
    }

    display: grid;
    width: 100px;
    margin-left: 2px;
    align-items: center;
    grid-template-columns: 20px 40px 20px;
    grid-gap: var(--minimum-gap);

    &.card {
      grid-template-columns: 35px 90px 35px;
      position: relative;
    }

    &.product-view {
      margin: 0;
      width: 180px;
      grid-template-columns: 40px 70px 40px;
    }

    &.button {
      cursor: pointer;
      grid-template-columns: 160px;
      align-content: center;
      background-color: var(--blue);
      border-radius: var(--border-radius);
      font-size: var(--base-font-size);
      color: var(--white);
      line-height: 24px;
      height: 48px;
      margin: 0;
      padding: 5px 3px;
      border: 0px;
      width: 167px;

      &.disabled {
        background-color: #BBC0C9;
      }
    }

    @media (max-width: 713px) {
      grid-template-columns: 26px minmax(40px, 70px) 26px;
    }

    &-disabler {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--white);
      opacity: 0.4;

      @media (max-width: 713px) {
        left: 0px;
        top: 295px;
        height: 40px;
        width: 170px;
      }

      @media (max-width: 480px) {
        top: 275px;
      }
    }

    @media (max-width: 480px) {
      visibility: hidden;
    }

    &-increment,
    &-decrement {
      display: block;
      width: 20px;
      height: 20px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: contain;

      &.card {
        width: 32px;
        height: 32px;
      }

      @media (max-width: 713px) {
        width: 26px;
        height: 26px;
      }
    }

    &-cart-loader {
      display: flex;
      align-items: center;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.7);
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    &-increment {
      background-image: url("./../../images/plus.svg");
    }

    &-decrement {
      background-image: url("./../../images/minus.svg");
    }
  }

  &__label {
    display: block;
    font-size: var(--big-mid-font-size);
    line-height: var(--big-mid-gap);
    color: var(--grey);
    font-weight: 300;
    cursor: pointer;
    text-transform: uppercase;

    &--small {
      line-height: var(--h3-size);
      font-size: var(--thin-font-size);
    }

    &--big {
      line-height: var(--accent-size);
      font-size: var(--accent-size);

      @media (max-width: 480px) {
        line-height: 1;
        font-size: 20px;
      }
    }

    &--centered {
      text-align: center;
    }

    &--right {
      text-align: right;
    }

    &--disabled {
      pointer-events: none;
    }
  }

  &__checkbox {}

  &__button {
    border: none;
    cursor: pointer;
    outline: none;
    position: relative;
    font-size: var(--accent-size);
    width: 100%;
    height: 60px;
    border-radius: var(--border-medium-radius);
    font-weight: 400;
    margin-top: var(--small-gap);
    padding: 0 var(--small-mid-gap);

    @media (max-width: 480px) {
      height: 40px;
      font-size: 20px;
    }

    &:active {
      top: 1px;
      left: 1px;
    }

    &--neutral {
      background: var(--light-grey);
      color: var(--dark-grey);
    }

    &--secondary {
      background: var(--light-blue);
      color: var(--white);
    }

    &--positive {
      background: var(--green);
      color: var(--white);
    }

    &--show {
      display: block;
      background: var(--light-blue);
      color: var(--white);
      width: 189px;
      height: 30px;
      border-radius: 5px;
      font-size: 16px;
      margin: 10px auto
    }
  }

  &__search {
    position: relative;
    height: 60px;

    &>input {
      height: 100%;
      padding-left: 100px;
      font-size: var(--accent-size);

      &::placeholder {
        color: var(--medium-grey);
        font-family: var(--additional-font-family);
        font-weight: 300;
      }
    }

    &-icon {
      background: none;
      border: none;
      outline: none;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__popup {
    position: relative;
  }
}

.form-type-2 {
  font-weight: 300;

  .DayPickerInput {
    display: block;
    position: relative;
    z-index: 2;
  }

  .short {
    display: none;
  }

  .long {
    display: block;
  }

  @media (max-width: 1024px) {
    .short {
      display: block;
    }

    .long {
      display: none;
    }
  }

  input,
  textarea {
    display: block;
    width: calc(100% - calc(var(--border-thin-width) + var(--small-gap)) * 2);
    padding: 0 var(--small-gap);
    font-size: var(--h3-size);
    font-weight: 300;
    height: calc(var(--input-height) - calc(var(--border-thin-width) * 2));
    border: var(--border-thin-width) solid var(--light-grey);
    border-radius: var(--border-width);
    margin-top: var(--small-gap);

    &::placeholder {
      color: var(--medium-grey);
    }

    &.error {
      border-color: var(--red);
    }
  }

  button {
    position: relative;
    background-color: transparent;
    border: none;
    border-radius: var(--border-width);
    margin-top: var(--small-gap);
    font-size: var(--big-font-size);
    font-weight: 300;
    height: var(--input-height);
    width: 100%;
    cursor: pointer;

    img {
      position: absolute;
      top: 50%;
      left: var(--small-gap);
      transform: translateY(-50%);
    }

    &.button_grey {
      background-color: var(--light-grey);
      color: var(--dark);

      &.submitting {
        color: var(--light-grey);
      }
    }

    &.button_blue {
      background-color: var(--blue);
      color: var(--white);

      &.submitting {
        color: var(--blue);
      }
    }

    &.button_light-blue {
      background-color: var(--light-blue);
      color: var(--white);

      &.submitting {
        color: var(--light-blue);
      }
    }

    &.button_black {
      background-color: var(--black);
      color: var(--white);

      &.apple {
        img {
          height: 100px;
          top: 4px;
          left: 0;
        }
      }

      &.submitting {
        color: var(--light-grey);
      }
    }

    &.green {
      background-color: var(--green) !important;

      &.submitting {
        color: var(--green);
      }
    }
  }

  textarea {
    height: var(--textarea-height);
    padding: var(--small-gap);
    resize: vertical;
  }

  .field {
    position: relative;
    display: block;
    font-size: var(--h3-size);
    color: var(--orange);
    margin-bottom: var(--mid-gap);

    .loader {
      position: absolute;
      right: -60px;
      top: 50%;
      transform: translateY(-50%) scale(0.5);
    }

    button {
      .loader {
        right: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.5);
      }
    }

    input {
      outline: none;
    }

    &-error {

      input,
      textarea {
        border-color: var(--red);
      }
    }

    &.dropdown {

      &_open.dropdown {
        .input {
          span.arrow {
            &:after {
              transform: rotate(-45deg) translateY(5px) translateX(8px);
            }
          }
        }
      }

      .input {
        position: relative;
        z-index: 1;

        &-icon {
          input {
            padding-right: 42px;
            width: 100%;
            box-sizing: border-box;
          }
        }

        input {
          pointer-events: none;
        }

        span {
          overflow: hidden;
          --size: 40px;
          position: absolute;
          top: var(--minimum-gap);
          height: var(--size);
          width: var(--size);

          &.inner {
            right: var(--minimum-gap);
          }

          &.outer {
            left: calc(100% + var(--minimum-gap));
          }

          &.check,
          &.cross {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.cross {
            font-size: var(--accent-size);
          }

          &.arrow {
            &:after {
              content: '';
              position: absolute;
              top: var(--small-mid-gap);
              right: calc(var(--small-mid-gap) + 1px);
              width: var(--base-font-size);
              height: var(--base-font-size);
              transform: rotate(135deg) translate(-14px);
              border-top: solid 1px var(--grey);
              border-right: solid 1px var(--grey);
            }
          }
        }
      }

      .dropdown__overlay {
        color: var(--dark-grey);
        position: relative;
        margin-top: -2px;
        background-color: var(--light-grey);
        padding: var(--big-small-gap);

        &:empty {
          display: none;
        }

        span.error {
          font-size: var(--base-font-size);
          margin: var(--small-gap);
        }
      }

      .dropdown__item-wrapper {
        display: flex;
        font-size: var(--h3-size);

        &:not(:last-of-type) {
          margin-bottom: var(--small-gap);
        }
      }

      .dropdown__item {
        flex: 1;
        background-color: var(--white);
        border-radius: var(--border-width);
        padding: var(--small-gap) var(--big-small-gap);

        &_selected {
          position: relative;

          &:after {
            content: '✓';
            position: absolute;
            right: var(--minimum-gap);
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &-button {
          width: 36px;
          height: 53px;
          display: flex;
          align-items: center;

          color: white;
          margin: 0 0 0 var(--small-gap);
          padding: var(--big-small-gap);
          background-color: var(--light-blue);
          border-radius: var(--border-width);
        }
      }

      button {
        font-size: var(--h3-size);
        font-weight: bold;
        color: var(--white);

        &.green {
          background-color: var(--green);
        }

        &.blue {
          background-color: var(--light-blue);

          &.submitting {
            color: var(--light-blue);
          }
        }
      }
    }
  }

  @media (max-width: 713px) {

    .field,
    input,
    textarea {
      font-size: var(--mobile-form2-font-size);
    }

    input {
      padding-right: 55px;
      box-sizing: border-box;
      margin: 0;
      width: 100%;
    }

    .field.dropdown .input {
      span.outer {
        right: 6px;
        left: auto;
      }

      .loader {
        right: 0;
        top: 50%;
        transform: translateY(-50%) scale(0.5);
      }
    }
  }

  span.error {
    display: block;
    margin-top: var(--mid-gap);
    color: var(--red);
  }
}
