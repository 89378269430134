.cart {
  &__item {
    display: grid;
    grid-gap: var(--small-mid-gap);
    grid-template-columns: 3fr 10fr;
    justify-items: center;
    align-items: center;
    position: relative;
    padding: var(--big-small-gap) 0;

    &:not(:last-child) {
      border-bottom: var(--border-thin-width) solid var(--medium-grey);
    }

    @media (max-width: 480px) {
      padding: var(--minimum-gap) 0;
    }

    &--no-delete {
      grid-template-columns: minmax(350px, 1fr) 4fr;
    }

    &--with-checkout {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &--bordered-light {
      border-bottom: var(--border-thin-grey-light);
      margin-bottom: var(--mid-gap);
    }

    &-images {
      position: relative;
      align-self: start;
      grid-gap: var(--minimum-gap);
      max-width: 100%;
      height: 100%;

      &--additional {
        padding-top: 25px;
        margin-top: 25px;
      }

      &>*:first-child {
        grid-column: 1 / span 3;
      }
    }

    &-image {
      width: 100%;
      overflow: hidden;
      align-self: start;

      & img {
        max-width: 100%;
        max-height: 140px;
      }
    }

    &-properties {
      align-self: start;
      justify-self: start;
      box-sizing: border-box;
      padding-right: 40px;
      width: 100%;
      // padding-right: 10%;

      &--additional {
        border-top: var(--border-thin-grey-light);
        padding-top: 25px;
        margin-top: 25px;
        width: 100%;
      }
    }

    &-header {
      text-align: left;
      font-size: var(--big-accent-size);
      font-weight: 400;
      color: var(--dark);
      line-height: 1.4;

      @media (max-width: 480px) {
        font-size: var(--mobile-product-item-title);
        color: var(--dark);
        text-transform: none;
      }
    }

    &-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin-top: 0;
      color: var(--subsection-header-color);

      @media (max-width: 713px) {
        width: 90%;
      }

      @media (max-width: 480px) {
        font-size: var(--mobile-cart-item-title);
        color: var(--dark);
        text-transform: none;
      }
    }

    &-description {
      font-size: 16px;
      line-height: 24px;
      color: var(--subsection-header-color);
      font-weight: 400;
    }

    &-add-to-favorites {
      display: block;
      color: var(--orange);
      font-size: var(--h3-size);
      margin: 20px;
      position: absolute;
      font-weight: 300;

      &:before {
        display: block;
        position: absolute;
        width: 26px;
        height: 26px;
      }


      &:before {
        content: url('../../assets/images/empty-heart.svg');
      }

      &.active:before {
        content: url('../../assets/images/filled-heart.svg');
      }
    }

    &-prices {
      display: grid;
      grid-template-columns: 1fr 5fr 120px;
      grid-gap: var(--small-gap);
      margin-top: var(--big-gap);
      align-items: center;
    }

    &-price {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--main-text-color);
    }

    &-discount-price {
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: line-through;
      color: #8D96A6;
    }

    &-count {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: var(--small-gap);
    }

    &-remove {
      position: absolute;
      display: block;
      color: var(--red);
      overflow: hidden;
      width: 40px;
      height: 40px;
      top: 0;
      right: 0;
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 26px;
        height: 26px;
        background-image: url(../../assets/images/icons/delete.svg);
        background-size: contain;
      }

      /* for mobile 480 but need to fix <1000 */
      @media (max-width: 713px) {
        width: 26px;
        height: 26px;
        top: 5px;
        right: 5px;

        &:before {
          display: none;
        }

        &:after {
          top: 0;
          left: 0;
          transform: none;
          width: 26px;
          height: 26px;
        }
      }
    }

    &-offer {
      grid-column: 1 / span 3;
      justify-self: end;
      color: var(--light-blue);
      font-family: var(--accent-font-family);
      font-size: var(--mid-accent-size);
      text-align: right;
      margin-bottom: var(--big-small-gap);

      &--left {
        text-align: left;
        justify-self: start;
      }
    }

    &-discount {
      position: absolute;
      left: 0;
      top: 0;
      display: grid;
      justify-items: center;
      align-items: end;
      color: var(--white);
      z-index: 0;
      opacity: 0.9;

      &.sidebar {
        &:after {
          content: '';
          position: absolute;
          top: -45px;
          left: -45px;
          transform: rotate(-45deg);
          border-left: 45px solid transparent;
          border-right: 45px solid transparent;
          width: 90px;
          height: 90px;
          box-sizing: border-box;
          z-index: 1;
          border-bottom: 45px solid var(--orange);
        }
      }

      &-text {
        position: absolute;
        text-transform: uppercase;
        z-index: 2;
        top: -25px;
        left: -4px;
        width: 120px;
        transform: rotate(-45deg);
        font-weight: 700;

        &.sidebar {
          font-size: var(--thick-font-size);
          top: -30px;
          left: -7px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: -60px;
        left: -60px;
        transform: rotate(-45deg);
        border-left: 60px solid transparent;
        border-right: 60px solid transparent;
        width: 120px;
        height: 120px;
        box-sizing: border-box;
        z-index: 1;
        border-bottom: 60px solid var(--orange);
      }
    }

    &-total {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      color: #3E6D2E;
    }

    &--shadow {
      border-top: var(--border-thin-width) solid var(--medium-grey);
    }

    @media (max-width: 713px) {
      grid-template-columns: 70px 1fr;
      padding: 5px;
      margin-bottom: 10px;

      &-images {
        margin: 0.2em 0;
        display: block;
      }

      &-description {
        &:empty {
          display: none;
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: 25px;
    top: 25px;
  }

  &__multiple-stores {
    background-color: rgba(255, 101, 23, 0.1);
    border: 1px solid #FF6517;
    border-radius: 8px;
    padding: 20px 50px;
    margin-bottom: 30px;
  }

  &__discount-container {
    margin-top: 35px;
  }

  .small-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0D253E;
  }

  .tiny-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #0D253E;
  }
}

.subsection__cart {
  width: 1200px;
  max-width: 1200px !important;
}

.multi-cart {
  &__header {
    background-color: var(--background-grey);
    padding: 20px 50px 10px;

    &-number {
      font-size: 28px;
      line-height: 40px;
      font-weight: 500;
    }

    &-store {
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
    }

    &-address {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__body {
    display: flex;
    flex-direction: row;

    @media (max-width: 713px) {
      flex-direction: column;
    }

  }

  &__products {
    flex: 1;
  }

  &__order {
    flex: 1;
    position: relative;
  }

  &-title {
    color: var(--subsection-header-color);
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    text-transform: none;
  }

  &-delivery {
    padding: 6px 10px;
    border-radius: 8px;

    &.failed {
      border: 1px solid #FF6517;
      background-color: rgba(255, 101, 23, 0.1);
    }

    &.success {
      border: 1px solid var(--main-text-color);
      background-color: transparent;
    }
  }
}

.row {
  display: flex;
  column-gap: 20px;

  .field {
    flex: 1
  }
}

.form-type-2 {
  .field {
    color: var(--blue) !important;
  }
}
