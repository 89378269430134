.page__content.page__info {
  @media (max-width: 713px) {
    padding: 0;
  }
}

.information {
  $pad-top: var(--small-mid-gap);
  $pad: var(--mid-gap);
  $pad-bottom: var(--big-big-gap);
  grid-column: 1 / 4;
  box-sizing: border-box;

  @media (max-width: 713px) {
    margin: unset;
  }

  $header-height: 256px;
  &-header {
    height: $header-height;
    background-image: url('../../assets/images/information-background.jpg');
    background-size: cover;
    position: relative;

    @media (max-width: 713px) {
      height: 10rem;
      background-size: cover;
    }

    & > img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: $header-height;
      background-color: transparent;

      @media (max-width: 713px) {
        width: 10rem;
      }
    }

    & > div {
      position: absolute;
      bottom: 0;
      font-family: var(--accent-font-family);
      font-size: var(--h2-size);
      color: var(--blue);
      left: 50%;
      transform: translate(-50%, 0);
      margin-bottom: var(--minimum-gap);

      @media (max-width: 713px) {
        font-size: 22px;
        min-width: 197px;
        text-align: center;
      }
    }
  }

  &-content {
    p {
      font-size: var(--big-font-size);
    }
    font-weight: 300;
    letter-spacing: normal;
    text-align: justify;
    color: var(--dark);
    margin: var(--small-mid-gap);

    @media (max-width: 713px) {
      margin: var(--mid-gap);
    }
  }
}
