html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 320px;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}
