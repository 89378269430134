.search-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 255px;
  
  & > h3 {
    color: var(--subsection-header-color);
    font-weight: 600;
    font-size: var(--accent-size);
    text-transform: capitalize;
    margin-bottom: 30px;
  }

  &__chapter {
    padding: 8px 0px 8px;
    font-weight: 500;
    font-size: var(--base-font-size);
    color: var(--subsection-header-color);
    cursor: pointer;

    &--active {
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__sub-chapter {
    padding: 8px 0px 8px;
    font-weight: 400;
    font-size: var(--base-font-size);
    color: var(--subsection-header-color);
    cursor: pointer;
    display: flex;
    align-items: center;

    .form__checkbox {
      width: 16px;
      height: 16px;
      min-width: 16px;
      margin-right: 10px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 1140px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 20px;
    margin-bottom: 20px;
    
    & > h3 {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  @media (max-width: 713px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}