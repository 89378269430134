* {
  font-family: var(--base-font-family);
}

h1 {
  font-family: var(--accent-font-family);
  font-size: var(--h1-size);
  color: var(--blue);
  text-align: center;
  font-weight: 400;
  margin: var(--minimum-gap) 0 var(--big-mid-gap);

  @media (max-width: 713px) {
    font-size: 30px;
    margin: var(--minimum-gap) 0 var(--small-gap);
  }
}

h2 {
  font-weight: 400;
  font-size: var(--h2-size);
  color: var(--dark);
  margin: 0 0 var(--small-gap);
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 713px) {
    font-size: 22px;
    margin: 0 0 var(--minimum-gap);
  }
}

h3,
h4,
.h3,
.h4 {
  color: var(--blue);
  font-size: var(--h3-size);
  margin: var(--small-mid-gap) 0 var(--small-gap);
  font-weight: 400;
  text-transform: uppercase;
}

h4 {
  font-weight: 500;
  margin: 0;
  font-size: var(--big-mid-font-size);
}

p {
  color: var(--dark-grey);
  font-size: var(--accent-size);
  font-weight: 300;
  margin: var(--minimum-gap) 0 var(--small-mid-gap);
}

a {
  color: var(--light-blue);
  text-decoration: none;
}

.text-center {
  text-align: center;
}


@media (max-width: 480px) {
  p {
    font-size: var(--mobile-default-font-size);
  }
}
