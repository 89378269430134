.swiper-pagination {
  @media (max-width: 713px) {
    position: relative;
  }

  .swiper-pagination-bullet {
    background: var(--purple);
  }
}

.page__image-container > .swiper-container {
  .swiper-wrapper {
    padding-bottom: 26px;
  }
}
