.burger-menu {
  z-index: 5;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 410px;
  max-width: 410px;
  overflow: auto;
  background-color: var(--white);
  top: 30px;
  font-weight: 300;
  left: -410px;
  border-width: 1px;
  border-top-style: none;
  box-shadow: 0 2px 10px 0 #9b9b9b;
  height: calc(100vh - 61px);;
  visibility: hidden;
  -webkit-transition: left 0.3s ease, visibility 0.3s ease;
  -moz-transition: left 0.3s ease, visibility 0.3s ease;
  -o-transition: left 0.3s ease, visibility 0.3s ease;
  transition: left 0.3s ease, visibility 0.3s ease;
  &.opened {
    left: 0;
    visibility: visible;
  }

  @media (max-width: 1024px) {
    height: calc(100vh - 52px);
    overflow: auto;
  }

  @media (max-width: 713px) {
    height: calc(100vh - 58px);
    overflow: auto;
    left: -9px;
    top: 28px;
  }

  @media (max-width: 480px) {
    overflow: auto;
    height: calc(100vh - 52px);
    left: -510px;
    top: 24px;
  }

  &__cross {
    display: block;

    button {
      float: right;
      background-image: url('../../../../assets/images/cancel.svg');
      height: 24px;
      width: 24px;
      border: none;
      background-color: transparent;
      margin: 8px 8px 0;
    }
  }

  &__user {
    display: block;
    flex-direction: column;
    color: var(--dark);
    padding: 10px 0 32px 10px;
    &.slim {
      padding: 10px 0 0 0;
    }

    .user__name {
      height: 52px;
      font-size: var(--h3-size);
      display: flex;
      align-items: center;

      button {
        margin-left: 12px;
        background-image: url('../../../../assets/images/edit.svg');
        height: 24px;
        width: 24px;
        border: none;
        background-color: transparent;
      }
    }

    .user__address {
      font-weight: 500;
    }
  }

  &__links {
    position: relative;
    text-decoration: none;
    font-size: var(--big-mid-font-size);
    font-weight: 500;

    .dimmer {
      width: 100%;
      height: 100%;
      opacity: 1;
    }

    .links-item {
      display: flex;
      color: var(--blue);
      border-bottom: 1px solid var(--slightly-drak-grey);
      margin-left: 10px;
      padding: 10px;

      &__text {
        margin-left: 13px;
      }

      &__arrow {
        margin-left: auto;
      }
    }
  }

  &__shop {
    padding: 40px 0 0 10px;
    line-height: 22px;

    &-header {
      padding: 15px 0;
      font-weight: 700;
      font-size: var(--big-mid-font-size);
      color: var(--light-blue);
    }

    .accordion-item__header {
      display: flex;
      align-items: center;
      height: 45px;
      border-bottom: 1px solid var(--slightly-drak-grey);
      cursor: pointer;

      &.expanded {
        border-bottom: none;
      }

      &-image {
        height: 40px;
        width: 40px;
      }

      &-text {
        color: var(--blue);
        font-size: var(--big-mid-font-size);
        padding-left: 15px;
        font-weight: 500;
      }

      &-arrow {
        margin-left: auto;
        padding-right: 13px;

        img {
          transition: transform 0.3s ease;
          transform: rotate(90deg);
          transform-origin: center;
        }

        img.expanded {
          transform: rotate(-90deg);
        }
      }
    }

    .accordion-item__body {
      display: flex;
      flex-direction: column;
      font-size: var(--big-mid-font-size);
      overflow: hidden;
      height: 0;
      //transition: all 0.2s linear;

      &.expanded {
        height: 100%;
      }

      &-item {
        padding: 10px 0 10px 55px;
        color: var(--blue);
        font-weight: 700;
        font-size: var(--minimum-font-size);
      }
    }
  }

  &__contacts {
    padding: 40px 0 0 10px;
    line-height: 22px;

    &-header {
      padding: 15px 0;
      font-weight: 700;
      font-size: var(--big-mid-font-size);
      color: var(--light-blue);
    }

    &-body {
      font-weight: 500;
      padding-bottom: 80px;
      font-size: var(--big-mid-font-size);
      * {
        padding: 6px 0;
        display: block;
        color: var(--blue);
      }
    }
  }
}
