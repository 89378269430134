@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import 'react-notifications-component/dist/theme.css';

:root {
  /* colors */
  --white: #ffffff;
  --orange: #f5a623;
  --dark-orange: #e07e2d;
  --corporate-orange: #FF6517;
  --red: #be0c0c;
  --purple: #5755d5;
  --violet: #8C63AA;
  --scarlet: #d0021b;
  --week-red: #F65959;
  --green: #7ed321;
  --light-blue: #4b9fe9;
  --crimson: #FF0000;
  --green-blue: #34c658;
  --light-green: #b8e986;
  --lighter-blue: #8cd5ff;
  --slightly-light-blue: #F4F8FB;
  --slightly-light-grey: #faf9fa;
  --slightly-grey: #F2F3F5;
  --light-grey: #f1f1f1;
  --background-grey: #F8F8F8;
  --medium-grey: rgba(110, 110, 110, 0.3);
  --grey: #8D96A6;
  --slightly-drak-grey: #d8d8d8;
  --dark-grey: #6e6e6e;
  --dark: #4a4a4a;
  --black: #000000;
  --light-overlay: rgba(255, 255, 255, 0);
  --dark-overlay: rgba(155, 155, 155, 0.1);
  --dark-overlay-strong: rgba(0, 0, 0, 0.5);
  --subsection-header-color: #0D253E;
  --search-placeholder-color: #9B9B9B;

  /* fonts */
  --h1-size: 50px;
  --h2-size: 40px;
  --h3-size: 24px;
  --thin-font-size: 10px;
  --thick-font-size: 12px;
  --minimum-font-size: 14px;
  --small-font-size: 15px;
  --base-font-size: 16px;
  --mid-font-size: 17px;
  --big-mid-font-size: 18px;
  --big-font-size: 20px;
  --accent-size: 28px;
  --mid-accent-size: 30px;
  --big-accent-size: 32px;
  --giant-font-size: 80px;
  --base-font-family: 'Inter';
  --accent-font-family: 'Inter';
  --alter-font-family: 'Inter';
  --additional-font-family: 'Inter';
  --lato-font-family: 'Inter';
  --announcement-font-family: 'Inter';

  --mobile-default-font-size: var(--minimum-font-size);
  --mobile-cart-item-title: var(--mid-font-size);
  --mobile-product-item-title: var(--big-font-size);
  --mobile-form-input-font-size: var(--big-font-size);
  --mobile-form-input-height: 26px;
  --mobile-categoty-nav-font-size: 16px;
  --mobile-form2-font-size: 20px;

  /* shadows */
  --light-shadow: #e1e1e1;
  --light-box-shadow: 0 2px 4px 0 var(--light-shadow);
  --light-medium-box-shadow: 0 3px 8px 0 var(--light-shadow);
  --medium-shadow: rgba(0, 0, 0, 0.3);
  --medium-box-shadow: 0 2px 10px 0 var(--medium-shadow);
  --dark-shadow: rgba(0, 0, 0, 0.5);
  --dark-box-shadow: 0 2px 12px 0 var(--dark-shadow);
  --mobile-medium-box-shadow: 0 2px 4px 0 #f1f1f1;

  /* borders */
  --border-thin-width: 1px;
  --border-thin-grey: var(--border-thin-width) solid var(--grey);
  --border-thin-grey-light: var(--border-thin-width) solid var(--medium-grey);
  --border-small-width: 2px;
  --border-thin-orange: var(--border-small-width) solid var(--orange);
  --border-width: 4px;
  --border-small-radius: 3px;
  --border-medium-radius: 5px;
  --border-radius: 8px;
  --border-big-radius: 30px;

  /* gaps */
  --minimum-gap: 5px;
  --small-gap: 10px;
  --smaller-gap: 7px;
  --big-small-gap: 15px;
  --mid-gap: 25px;
  --small-mid-gap: 20px;
  --big-mid-gap: 35px;
  --sm-big-gap: 50px;
  --big-gap: 55px;
  --big-big-gap: 75px;

  /* sizes */
  --page-side-margin: 120px;
  --sidebar-width: 265px;
  --item-width: 255px;
  --item-height: 300px;
  --input-height: 50px;
  --textarea-height: 175px;

  /* modal */
  --close-icon-size: 40px;
  --mobile-close-icon-size: 25px;
}
