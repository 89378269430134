.loader {
  margin: 0 auto;
  width: 64px;
  height: 64px;

  &.product-item {
    width: 44px;
    height: 44px;
  }

  &.accordion-item__header-image {
    padding-bottom: 10px;
    margin: auto 0;
    width: 30px;
    height: 20px;

    div {
      width: 20px;
      height: 20px;
      border: 3px solid #fff;
      border-radius: 50%;
      animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
    }
  }

  &.department-loader {
    width: 148px;
    height: 148px;

    @media (max-width: 480px) {
      width: 60px;
      height: 60px;
    }

    @media (min-width: 481px) and (max-width: 713px) {
      width: 110px;
      height: 110px;
    }
  }

  &.marged {
    margin: var(--mid-gap) auto;
  }

  &_full-central {
    transform: translate(-50%, -50%) scale(0.5);
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &_add-cart {
    transform: translate(-50%, -50%) scale(0.5);
    position: absolute;
    top: 50%;
    left: 34%;
  }

  &.product-counter {
    display: flex;
    align-items: flex-end;
    position: relative;
    height: 40px;
    width: 43px;
    bottom: 2px;


    div {
      width: 31px;
      height: 31px;
    }

    @media (max-width: 713px) {
      bottom: 0;

      div {
        margin-top: 3px;
        margin-left: 11px;
        width: 21px;
        height: 21px;
        border: 3px solid #fff;
        border-radius: 50%;
        animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
      }
    }
  }
}

.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.loader.product-item div {
  width: 32px;
  height: 32px;
  margin: 2px;
  border-width: 2px;
}

.loader.department-loader div {
  width: 135px;
  height: 135px;

  @media (max-width: 480px) {
    width: 50px;
    height: 50px;
  }

  @media (min-width: 481px) and (max-width: 713px) {
    width: 100px;
    height: 100px;
  }
}

.loader.loader_orange div {
  border-top-color: var(--orange);
}

.loader.loader_red div {
  border-top-color: var(--red);
}

.loader.loader_blue div {
  border-top-color: var(--light-blue);
}

.loader.loader_blue {
  &.big {
    width: 500px;
    height: 500px;
    position: absolute;

    div {
      z-index: 1;
      width: 400px;
      height: 400px;
      margin-left: 70px;
      margin-top: 20px;
    }

    @media (max-width: 480px) {
      width: 300px;
      height: 300px;

      div {
        width: 300px;
        height: 300px;
        margin-left: 30px;
      }
    }
  }
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
