.departments {
  &-more{
    text-align: right;
    margin-right: 15px;
    font-size: var(--big-mid-font-size);
    font-weight: 700;
    color: var(--blue);
    cursor: pointer;
    @media (max-width: 1024px) {
      display: none
    }
  }

  h3 {
    height: 50px;
    line-height: 50px;
    text-transform: none;
    color: var(--dark);
    padding-left: 80px;
    margin: 0 0 10px 0;
    font-weight: 900;
  }

  &-wrap {
    display: grid;
    grid-template-columns: 240px 240px 240px 240px;
    column-gap: var(--sm-big-gap);
    row-gap: var(--small-gap);
    justify-content: center;
    &.mobile {
      display: none;
    }
    @media (max-width: 1024px) {
      display: none;
      justify-content: unset;
      &.mobile {
        display: block
      }
    }
    .department {
      width: 240px;
      height: 250px;

      overflow: hidden;
      box-shadow: var(--medium-box-shadow);
      font-size: var(--big-accent-size);
      color: var(--dark);
      border-radius: var(--border-radius);
      margin: 15px;
      position: relative;

      &__cover {
        img {
          width: 100%;
        }
      }

      &__name {
        position: absolute;
        display: flex;
        bottom: 0;
        width: 100%;
        height: 68px;
        line-height: 34px;
        text-align: center;
        font-size: 24px;
        font-family: var(--base-font-family);
        font-weight: 400;
        background: var(--white);
        justify-content: center;
        color: var(--light-blue);
        span {
          align-self: center;
        }
        @media (max-width: 1024px) {
          height: 120px;
          justify-content: unset;
        }
        @media (max-width: 480px) {
          height: 68px;
        }
      }

      &__item-info {
        text-align: center;
      }

      &__link {
        display: none;
        background-repeat: no-repeat;
      }
      @media (max-width: 1024px) {
        box-shadow: var(--mobile-medium-box-shadow), var(--mobile-medium-box-shadow);
        position: relative;
        height: 120px;
        width: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        border-bottom: 1px solid var(--light-grey);

        &__cover img {
          width: auto;
          height: 100%;
        }

        &__cover {
         height: 120px;
         width: 120px;
        }

        &__name {
          text-align: left;
          line-height: 120px;
          left: 120px;
          top: 0;
        }

        &__link {
          display: block;
          background-image: url(../../assets/images/arrow-yellow-button.svg), linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
          width: 25px;
          height: 36px;
          align-self: center;
          background-size: contain;
          margin: var(--small-gap);
          margin-left: auto;
          z-index: 1;
        }
      }

      @media (max-width: 480px) {
        position: relative;
        box-shadow: var(--mobile-medium-box-shadow), var(--mobile-medium-box-shadow);
        height: 55px;
        width: 100%;

        &__cover {
          height: 55px;
          width: 68px;
        }

        &__link {
          width: 12px;
          height: 12px;
        }

        &__name {
          font-size: var(--mid-font-size);
          line-height: 55px;
          left: 60px;
          top: 0;
        }
      }
    }
  }

  &.store {
    margin-top: 50px;
    margin-bottom: 75px;

    h3 {
      display: none;
    }

    .departments-wrap {
      column-gap: var(--sm-big-gap);
      row-gap: var(--mid-gap);
    }

    .department {
      margin: 0;

      &__name {
        height: 73px;
      }
    }
  }

  &-loader-wrapper {
    .loading & {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: var(--light-grey);
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
  }
}
