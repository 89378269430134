.page {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-template-columns: 1fr minmax(0, 1300px) 1fr ;
  grid-template-areas:
    "header header header"
    "image image image"
    "page-left-margin page-main page-right-margin"
    "footer footer footer";
  // background: linear-gradient(180deg, rgba(34, 69, 133, 0.28) 0%, rgba(255, 255, 255, 0) 100%);

  min-height: 100vh;

  &.not-main {
    background: white;
  }

  @media (max-width: 1024px) {
    & {
      grid-template-columns: 0 minmax(0, 1fr) 0;
    }
  }

  @media (max-width: 480px) {
    & {
      padding: 0;
    }
  }

  &__image-container {
    cursor: pointer;
    background-color: transparent;
    grid-area: image;
    justify-content: center;
    overflow: hidden;
    &__item {
      display: grid;
      height: 250px;
      width: 100%;
      position: relative;
      overflow: hidden;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      z-index: 2;
      cursor: pointer;
      &-blue {
        position: absolute;
        background-color: #034ea1;
        height: 100%;
        z-index: 1;
        width: 120%;
        left: -10%;
        overflow: hidden;
        top: -95%;
        transform: rotate(-3deg);
        &.orange {
          background-color: #ff7901;
          top: unset;
          bottom: -95%;
        }
      }
    }
    &__universal-item {
      margin: auto;
      display: grid;
      justify-content: center;
      height: auto;
      position: relative;
      img {
        max-width: 1300px;
        height: auto;
        @media (max-width: 1024px) {
          max-width: 100%;
        }
      }
      &-header {
        position: absolute;
        font-family: var(--announcement-font-family);
        line-height: 77px;
        max-width: 400px;
        font-size: 62px;
        font-weight: 400;
        padding: 25px 0 0 35px;
      }
      &-discription {
        position: absolute;
        padding: 50px 0 0 35px;
        max-width: 400px;
        font-weight: 200;
        align-self: center;
        line-height: 40px;
        font-size: 34px;
        font-style: italic;
      }
      &-additionalText {
        position: absolute;
        max-width: 400px;
        padding: 7px 0 5px 35px;
        align-self: flex-end;
      }
    }
  }

  &__header {
    grid-area: header;
    position: sticky;
    top: 0;
    z-index: 5;
  }

  &__main {
    grid-area: page-main;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      "content"
      "footer";
    border-radius: var(--border-radius);
    overflow: hidden;
    margin: 10px 5px 0;

    @media (min-width: 481px) and (max-width: 713px) {
      border-radius: 0;
      margin: 10px 0 0 0;
    }

    @media (max-width: 480px) {
      border-radius: 0;
      margin: 10px 0 0 0;
    }
  }

  &__header {
    grid-area: header;

    @media (max-width: 480px) {
      border-width: 2px;
    }
  }

  &__content {
    grid-area: content;
    padding-bottom: var(--small-gap);

    @media (max-width: 713px) {
      border-radius: 0;
      padding-bottom: var(--minimum-gap);
    }
  }

  &__footer {
  }

  &__left-margin {
    display: none;

    grid-area: page-left-margin;
    justify-self: end;
    margin: var(--small-mid-gap) var(--small-gap) 0;

    @media (max-width: 1024px) {
      & {
        display: none;
      }
    }
  }

  &__right-margin {
    display: none;

    grid-area: page-right-margin;
    justify-self: start;
    margin: var(--small-mid-gap) var(--small-gap) 0;

    @media (max-width: 1024px) {
      & {
        display: none;
      }
    }

    & .menu--social-links .menu__image {
      width: 64px;
    }
  }

  &__scroll-up {
    position: sticky;
    top: 120px;
    display: grid;
    padding: var(--minimum-gap);
    align-items: end;
    justify-items: center;
    background: var(--light-grey);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    width: 100px;
    height: 65px;
    color: var(--grey);
    text-decoration: none;
    transition: opacity .1s ease;

    &:hover {
      opacity: 0.8;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 25%;
      width: 40%;
      height: var(--minimum-gap);
      border-radius: var(--border-radius);
      background: var(--grey);
    }

    &:before {
      left: 13%;
      transform: rotate(335deg);
      border-bottom-right-radius: 0;
    }

    &:after {
      left: 46%;
      transform: rotate(25deg);
      border-bottom-left-radius: 0;
    }
  }
}

.centered {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
