$mobile-max-width: 480px;

.footer {
  @media (min-width: 670px) {
    &.mobile {
      display: none;
    }
  }
  @media (max-width: 669px) {
    &:not(.mobile) {
      display: none;
    }
  }

  grid-area: footer;
  display: flex;
  background-color: var(--slightly-light-blue);
  justify-content: space-around;
  flex-wrap: wrap;
  font-size: var(--minimum-font-size);
  line-height: var(--h3-size);
  font-weight: 400;
  padding: var(--big-gap) 0;

  @media (max-width: 480px) {
    padding-left: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  &__block {

    @media (max-width: $mobile-max-width) {
      min-width: 180px;
      justify-content: unset
    }

    // &--info {
    //   li:last-child {
    //     font-weight: 400;
    //   }
    // }

    & h3 {
      font-weight: 500;
      margin: 0 0 var(--small-mid-gap);
      color: var(--main-text-color);
      line-height: 28px;
      font-size: 20px;
    }

    &--container {
      min-width: unset;
      display: grid;
      grid-template-rows: min-content;
      &.image {
        @media (max-width: 480px) {
          align-self: center;
        }
      }
      img {
        margin: 0 0 var(--small-mid-gap);
        width: 160px;
        align-self: flex-end;
     }
     nav {
       align-self: flex-start;
     }
    }

    &--contacts {
      address {
         font-style: normal;
      }
      a {
        font-weight: 400;
      }
      &-telephone {
        color: var(--blue)!important;
        font-size: 22px;
        line-height: 32px;
      }
    }

    &--links {
      @media (max-width: $mobile-max-width) {
        overflow: auto;
        grid-column: 1/3;
      }
      @media (min-width: 481px) and (max-width: 713px) {
        overflow: auto;
        grid-column: 1/3;
      }
      @media (max-width: $mobile-max-width) {
        overflow: auto;
        grid-column: 1/3;
        h3 {
          text-align: left;
        }
      }
    }
  }
  .menu--vertical.menu__container {
    grid-auto-rows: auto;
  }
  & .menu--social-links .menu__image {
    width: 38px;

    @media (max-width: $mobile-max-width) {
      width: 32px;
    }
  }

  &__application-store-link {
    height: 33px;
    border-radius: 4px;
  }

  & a {
    color: var(--main-text-color);
    text-decoration: none;
  }

  @media (max-width: 813px) {
    font-size: var(--big-mid-font-size);

    & h3,
    & h4 {
      font-size: var(--big-mid-font-size);
      margin: var(--small-gap) 0 var(--minimum-gap);
    }
  }

  @media (max-width: $mobile-max-width) {
    font-size: var(--mobile-small-font-size);
    line-height: var(--mobil-line-height);

    border-top-width: var(--border-thin-width);

    .menu--footer {
      .menu__item {
        margin: 0.1em 0;
      }
    }

    .menu--horizontal .menu__container {
      grid-auto-columns: min-content;
    }
    .menu--vertical.menu__container {
      grid-auto-rows: min-content;
    }

    .footer__block {
      margin-bottom: 1em;
      &--links {
        .menu__item {
          display: flex;
          justify-self: center;

        }
      }
    }

    & h3,
    & h4 {
      font-size: var(--mobile-small-font-size);
    }

    .menu--appstore {
      .menu__container {
        grid-auto-columns: min-content;

        .footer__application-store-link {
          height: 33px;
        }
      }
    }
  }

  @media (max-width: 1370px) {
    .footer__block--contacts {
      grid-column-end: unset;
    }
  }
}

.ccpa__link {
  height: 20px;
  vertical-align: middle;
  margin-left: 4px;
}
