.menu {
  &__container {
    display: grid;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (max-width: 480px) {
      grid-gap: var(--minimum-gap);
    }

    @media (min-width: 481px) and (max-width: 713px) {
      grid-gap: var(--minimum-gap);
    }
  }

  &--sticky {
    position: sticky;
    top: 120px;
  }

  &--horizontal &__container,
  &--horizontal-wide &__container,
  &--pagination &__container {
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-gap: var(--small-gap);
    justify-content: center;

    @media (max-width: 480px) {
      grid-gap: var(--minimum-gap);
      justify-content: flex-start;
    }

    @media (min-width: 481px) and (max-width: 713px) {
      grid-gap: var(--minimum-gap);
    }
  }

  &--horizontal-wide &__container {
    grid-auto-columns: auto;
  }

  &--pagination {
    margin: var(--big-mid-gap) 0;
    display: grid;
    justify-items: center;
  }

  &__link {
    text-decoration: none;

    &.google {
      margin-left: 12px;
    }

    &:hover {
      opacity: 0.75;
    }

    &--active {
      font-weight: 500;
    }

    .menu--pagination & {
      display: block;
      box-sizing: border-box;
      text-align: center;
      font-size: var(---h3-size);
      line-height: 40px;
      width: var(--big-gap);
      height: 40px;
      border: var(--border-thin-width) solid var(--grey);
      color: var(--dark-grey);
      border-radius: var(--border-medium-radius);

      &:hover,
      &--active {
        border-color: var(--light-blue);
        background: var(--light-blue);
        color: var(--white);
        opacity: 1;
      }
    }
  }

  &__dots {
    cursor: default;
    display: block;
    box-sizing: border-box;
    text-align: center;
    font-size: var(---h3-size);
    line-height: 40px;
    width: var(--big-gap);
    height: 40px;
    color: var(--dark-grey);
    border-radius: var(--border-medium-radius);
  }
}
