.content {
  display: grid;
  grid-template-areas:
      "center";

  @media (max-width: 1024px) {
    grid-template-areas:
      "center";
  }

  &__catalog {
    grid-area: center;
  }

  &__center {
    grid-area: center;
    overflow: hidden;
  }

  &__sidebar {
    width: var(--sidebar-width);
    padding: var(--small-gap);
    box-sizing: border-box;
    grid-area: left;

    &-toggle-visibility {
      display: none;
    }

    &--left {
      grid-area: left;
      border-right: var(--border-thin-width) solid var(--grey);
    }

    &--right {
      grid-area: right;
      border-left: var(--border-thin-width) solid var(--grey);
    }

    @media (max-width: 1024px) {
      width: auto;
      border: none;
      padding: 0;
      padding-bottom: var(--small-gap);
      max-height: 75px;
      overflow: hidden;
      transition: max-height 1.5s ease-out;
      position: relative;

      &-toggle-visibility {
        display: block;
        position: relative;
        z-index: 1;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
      }

      &--active {
        max-height: 100000px;
        transition: max-height 5s ease-in;

        &:after {
          display: none;
        }
      }
    }
  }

  &__load-more {
    display: grid;
    width: var(--item-width);
    height: var(--item-height);
    align-items: end;
    background-image: url("../../../assets/images/load-more.svg");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: bottom 60% center;
    text-decoration: none;
    transition: all 0.1s ease;
    p {
      all: unset;
      font-family: var(--base-font-family);
      color: #8C63AA;
      font-size: var(--mid-font-size);
      font-weight: 400;
      text-align: center;
      padding-bottom: 70px;

      @media (max-width: 480px) {
        padding-bottom: 40px;
      }
    }

    &:hover {
      opacity: 0.9;
    }
  }
}
