.form__input--search {
  width: 500px;
  height: 60px;

  @media (max-width: 713px) {
    width: 100%;
  }
}

.search-loader {
  height: 100%;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.search-result {
  width: 100%;
  padding-bottom: 65px;

  &__content {
    display: flex;
    align-items: flex-start;
    column-gap: 50px;
    margin-bottom: 60px;

    @media (max-width: 1140px) {
      flex-direction: column;
    }
  }

  &__body {
    width: 100%;

    @media (max-width: 713px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__items {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 18px 50px;

    @media (max-width: 1140px) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 10px 30px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  &__item {
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      height: 100px;
      object-fit: cover;

      @media (max-width: 813px) {
        height: 140px;
      }
    }

    &-title {
      margin: 8px 0px;
      font-size: var(--h3-size);
      color: var(--subsection-header-color);
      font-weight: 400;
    }

    &-description {
      font-size: var(--minimum-font-size);
      font-weight: 400;
      color: var(--grey);
    }
  }

  &__more{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 9px;
    border: 1px solid var(--slightly-grey);
    cursor: pointer;
    transition: border .2s;

    &-icon {
      width: 72px;
      height: 72px;
      background-image: url('../../../../assets/images/icons/load.svg');
    }

    span {
      color: var(--light-blue);
      font-size: var(--h3-size);
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 16px;
    }

    &:hover {
      border: 1px solid var(--light-blue);
    }
  }

  &__category {

    &-title {
      color: var(--subsection-header-color);
      background-color: var(--background-grey);
      font-size: var(--h3-size);
      padding: 10px 16px;
      border-radius: 8px 8px 0px 0px;
      text-transform: uppercase;
    }
  }
}

.header__search--input-wrapper {
  position: relative;
  border-bottom: 1px var(--dark-orange);
  border-top: 1px var(--dark-orange);

  .mobile-search.form__input.form__input--search {
    width: 100%;
    height: 25px;
    border-left: none;
    border-right: none;
    border-radius: unset;
    background-color: var(--slightly-light-grey);
    background-repeat: no-repeat;
    color: var(--subsection-header-color);

    @media (min-width: 713px) {
      display: grid;
      justify-self: center;
      padding-right: 60px;
      height: 42px;
      margin-left: 0;
      background-color: var(--white);
      border: var( --border-thin-width) solid var(--corporate-orange);
      border-radius: 5px;
    }

    &::placeholder {
      color: var(--search-placeholder-color);
    }

    &:focus {
      border-color: var(--slightly-light-grey);
    }
  }
  .icon {
    position: absolute;
    cursor: pointer;
    height: 40px;
    width: 48px;
    background-image: url('../../../../assets/images/icons/search.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-color: var(--dark-orange);
    top: 0;
    right: 0;
    border-left: none;
    border-bottom: var( --border-thin-width) solid var(--dark-orange);
    border-right: var( --border-thin-width) solid var(--dark-orange);
    border-top: var( --border-thin-width) solid var(--dark-orange);
    border-radius: 0 5px 5px 0;
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .header__search-results {
    position: absolute;
    top: 48px;
    box-sizing: border-box;
    background-color: var(--white);
    border: 1px solid var(--light-blue);
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    z-index: 2;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 713px) {
      max-width: 100%;
      top: 25px;
      border-radius: 0;
      border-top: unset;
      border-right: unset;
      border-left: unset;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      &-list {
        width: 100%;

      }

      &-more {
        width: 100%;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .more-icon {
          width: 24px;
          height: 24px;
          background-size: contain;
          background-image: url('../../../../assets/images/icons/load-small.svg');
          margin-right: 10px;
        }

        span {
          text-decoration: underline;
          color: var(--light-blue);
          font-size: var(--base-font-size);
          font-weight: 400;
          text-transform: uppercase;
        }
      }
    }
  }
}

.pre-search-item {
  width: 100%;
  height: 68px;
  display: grid;
  padding: 14px 8px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--light-grey);
  grid-template-columns: 40px 1fr 24px;
  grid-template-rows: 24px 16px;
  gap: 4px 8px;
  align-items: center;

  &__image {
    width: 40px;
    height: 40px;
    object-fit: contain;
    grid-area: 1 / 1 / 3 / 2;
  }

  &__name {
    grid-area: 1 / 2 / 2 / 3;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--subsection-header-color);
    font-size: var(--base-font-size);
    font-weight: 400;
  }

  &__title, &__price {
    grid-area: 2 / 2 / 3 / 3;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__title {
    color: var(--grey);
    font-size: var(--minimum-font-size);
    font-weight: 400;
  }

  &__price {
    color: var(--subsection-header-color);
    font-size: var(--base-font-size);
    font-weight: 500;
  }

  &__icon {
    grid-area: 1 / 3 / 3 / 4;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-image: url('../../../../assets/images/icons/arrow-right-black.svg');
    background-repeat: no-repeat;
  }

  &:hover {
    background-color: var(--background-grey);
    .pre-search-item {
      &__name, &__title, &__price {
        color: var(--light-blue);
      }
    }
  }
}
