.account-form {
  max-width: 500px;
  min-width: 320px;
  transition: visibility 1s, opacity .4s ease-out;
  opacity: 1;

  .error {
    text-align: center;
  }

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.5);
  }

  p {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;

    span {
      color: var(--blue);
      font-weight: 600;
      font-family: var(--accent-font-family);
      cursor: pointer;
    }
  }

  .subsection__forgot {
    color: var(--light-blue);
    cursor: pointer;
    text-align: end;
    font-size: var(--big-mid-font-size);
  }

  input {
    margin-top: unset !important;
    margin-bottom: 20px;

    &:focus-visible {
      outline-color: var(--blue);
    }
  }

  .account-business {
    height: 18px;
    font-size: 18px;
    line-height: 20px;
  }

  &.sign-up .subsection__header {
    color: var(--subsection-header-color);
    font-style: unset;
    text-align: unset;
  }

  &.hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}

.account-container {
  grid-template-columns: 1fr !important;
}

.account-text {
  font-size: var(--big-mid-font-size);
  margin: 40px 0;
  text-align: center;
  word-wrap: break-word;

  &.notification {
    color: var(--blue)
  }

  &.error {
    color: var(--dark);
  }
}

#business-account {
  display: flex;
  padding-top: 10px;

  input,
  label {
    color: var(--dark);
  }

  input {
    font-size: 14px;
    height: 20px;
    width: 30px;
    margin-top: unset;
  }
}

.no-margin-top {
  margin-top: 5px !important;
}

a.business-text-link {
  color: var(--white);
  text-decoration: underline;
}

.sign-up {
  &.success-login {
    width: 430px;
    text-align: center;
  }

  .business-text {
    padding-top: 20px;
    color: var(--grey);
    font-size: 20px;
    line-height: 1.5;
  }

  h2 {
    color: #7cb03d;
  }

  label {
    height: 14px;
    line-height: 14px;
    font-size: 12px;
  }
}

.sms-code__text {
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px 0;

  &.pad-top {
    padding-top: 60px;
  }
}

.sms-code__code {
  display: flex !important;
  justify-content: center;

  input {
    width: 30px !important;
    padding: 0 5px !important;
    margin: 0 5px !important;
    text-align: center !important;
  }
}

.sms-code__resend {
  color: var(--blue);
  cursor: pointer;
}

.relative {
  position: relative;
}
