.carousel {
  width: 100%;
  overflow: hidden;
  margin-bottom: 60px;
  box-sizing: border-box;

  @media (max-width: 713px) {
    padding: 0px 10px;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    & > h3 {
      line-height: 40px;
      text-transform: none;
      color: var(--light-blue);
      font-size: var(--accent-size);
      font-weight: 600;
      margin: 0;

      @media (max-width: 713px) {
        font-size: var(--big-font-size);
      }

      @media (max-width: 480px) {
        margin: 0;
      }
    }
  }

  .swiper-slide {
    width: 168px;
  }

  &__nav {
    display: flex;
    align-items: center;

    &-btn {
      width: 46px;
      height: 46px;
      border: 1px solid var(--light-blue);
      border-radius: 8px;
      outline: none;
      background: transparent;
      cursor: pointer;
      background-image: url('../../assets/images/arrow-right-carousel.svg');
      background-position: center;
      background-repeat: no-repeat;

      &.left {
        transform: rotate(-180deg);
      }

      &.disabled {
        pointer-events: none;
        cursor: default;
        opacity: .2;
      }

      &:last-child {
        margin-left: 24px;
      }
    }
  }

  &__body {
    max-width: 100%;
    display: block;

    &-load {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      height: 345px;
      overflow: hidden;
      grid-template-rows: 1fr;
      column-gap: 20px;

      @media (min-width: 300px) {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 15px;
      }

      @media (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 15px;
      }

      @media (min-width: 800px) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 15px;
      }

      @media (min-width: 900px) {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 15px;
      }

      @media (min-width: 1150px) {
        grid-template-columns: repeat(6, 1fr);
        column-gap: 20px;
      }
    }
  }

  &__announcements{
    height: 300px;
    margin-top: 40px;
    position: relative;
    margin-bottom: 40px;

    &__body {
      max-width: 100%;
      display: block;

      &-load {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 36px;
      }
    }
  }

  //styles for announcement
  &__prev,
  &__next {
    outline-color: transparent;
    outline-width: 0;
    border: none;
    background-color: transparent;
    display: block;
    width: 45px;
    height: 100%;
    position: absolute;
    background-image: url(../../assets/images/triangle.svg), linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    z-index: 4;
    cursor: pointer;
    user-select: none;

    &.hide {
     display: none;
    }

    @media (max-width: 480px) {
      background-image: url(../../assets/images/triangle.svg);
      width: 20px;
    }
    @media (min-width: 481px) and (max-width: 713px) {
      background-image: url(../../assets/images/triangle.svg);
      width: 20px;
    }
  }

  &__next {
    transform: translateY(-50%) rotate(0deg);
    left: auto;
    right: 0;
  }
}
