.header {
  display: grid;
  grid-template-areas:
    'info'
    'menu';
  align-items: center;
  padding-top: var(--smaller-gap);
  max-width: 100%;
  position: sticky;
  background-color: var(--blue);

  &__info {
    display: grid;
    grid-template-areas: 'catalog logo dashboard search shops account cart';
    align-items: center;
    grid-template-columns: 80px 100px 225px 1fr 80px 80px 80px;
    grid-gap: var(--small-mid-gap) var(--small-mid-gap);
    position: relative;
    padding-bottom: var(--smaller-gap);

    @media (max-width: 813px) {
      grid-template-columns: 80px 100px 140px 1fr 70px 70px 70px;
      grid-gap: var(--small-gap);
    }

    @media (max-width: 480px) {
      grid-template-columns: 80px 1fr 80px;
      grid-template-areas: 'catalog logo cart';
    }

    @media (min-width: 481px) and (max-width: 713px) {
      grid-template-columns: 70px 80px 1fr 80px;
      grid-template-areas: 'catalog dashboard logo cart';
      grid-gap: var(--small-gap);
    }
  }

  &__line {
    border-top: var(--border-thin-width) solid var(--light-grey);
    border-bottom: var(--border-thin-width) solid var(--light-grey);

    @media (max-width: 750px) {
      border: none;
    }
  }

  &__logo {
    display: grid;
    grid-area: logo;
    justify-self: start;

    img {
      height: 30px;
      width: 84px;
      margin-left: 10px;
      margin-bottom: 10px;

      @media (max-width: 813px) {
        margin-left: 0;
      }
    }

    .catalog__section {
      padding: 0;
    }

    @media (min-width: 481px) and (max-width: 713px) {
      justify-self: center;

      img {
        padding-bottom: 5px;
        margin-bottom: 0;
      }
    }

    @media (max-width: 480px) {
      justify-self: center;

      img {
        height: 35px;
        margin: 0;
      }
    }
  }

  &__search {
    display: grid;
    align-items: center;
    max-width: 750px;
    height: 100%;
    margin-right: 10%;
  }

  &__signup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--white);
    background-color: var(--warm-blue);
    justify-self: start;
    font-size: 12px;
    height: 32px;
    font-weight: 500;
    overflow: hidden;

    @media (max-width: 480px) {
      margin: 0;
      padding-right: 0;
      justify-content: flex-end;

      &--code,
      &--telefon {
        display: none;
      }

    }

    &.menu {
      justify-content: start;
      background-color: var(--white);
      color: var(--blue);
      font-size: var(--big-mid-font-size);
      font-weight: 400;
      line-height: 21px;
      cursor: pointer;
      padding-left: 10px;
      width: auto;
    }

    &--link {
      cursor: pointer;
      margin-right: 5px;
      color: var(--white);
      text-decoration: underline;
    }

    &--code {
      padding-left: 25px;
    }
  }

  &__link {
    color: var(--white);
    font-weight: 400;
    display: grid;
    justify-items: center;
    text-align: center;
    cursor: pointer;

    @media (min-width: 481px) and (max-width: 713px) {
      justify-items: baseline;
    }

    @media (max-width: 480px) {
      justify-items: baseline;
    }

    &--dashboard {
      grid-area: dashboard;
      position: relative;
      display: flex;

      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--orange);
        -webkit-transition: .4s;
        transition: .4s;
      }

      .slider:before {
        position: absolute;
        content: url('../../assets/images/truck.svg');
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }

      .scooter.slider:before {
        content: url('../../assets/images/scooter.svg');
      }

      input:checked+.slider {
        background-color: var(--warm-blue);
      }

      input:focus+.slider {
        box-shadow: 0 0 1px var(--warm-blue);
      }

      input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }

      @media (max-width: 480px) {
        display: none;
      }

      .dashboard-btn {
        display: block;
        box-sizing: border-box;
        text-align: center;
        width: 108px;
        min-width: 108px;
        height: 47px;
        border-radius: 8px;
        border: 1px solid var(--white);
        background: transparent;
        color: var(--white);
        font-size: 12px;
        margin-right: 8px;
        cursor: pointer;
        transition-duration: .2s;

        &--active {
          background: var(--white);
          color: var(--light-blue);
          pointer-events: none;
        }

        &:hover {
          background: var(--white);
          color: var(--light-blue);
        }

        &-asap:hover {
          background: var(--main-text-color);
          color: var(--white);
          border: 1px solid var(--main-text-color);
        }

        &-asap--active {
          background: var(--main-text-color);
          color: var(--white);
          border: 1px solid var(--main-text-color);
          pointer-events: none;
        }

        span {
          font-weight: 600;
        }

        @media screen and (max-width: 813px) {
          width: 64px;
          min-width: 64px;
          height: 44px;
          font-size: 8px;
        }
      }
    }

    &--catalog {
      grid-area: catalog;
    }

    &--account {
      grid-area: account;

      @media (max-width: 713px) {
        display: none;
      }
    }

    &--shops {
      grid-area: shops;

      @media (max-width: 713px) {
        display: none;
      }
    }

    &--cart {
      grid-area: cart;

      @media (max-width: 480px) {
        justify-items: end;
        padding-right: 20px;
      }

      @media (min-width: 481px) and (max-width: 713px) {
        justify-items: end;
        padding-right: 30px;
      }
    }

    &--catalog {

      @media (min-width: 481px) and (max-width: 713px) {
        justify-items: end;
        padding-left: 30px;
        padding-right: 10px;
      }
    }

    &--disabled {
      cursor: default;
      opacity: .5;
    }

    &--search {
      grid-area: search;

      @media (min-width: 481px) and (max-width: 713px) {
        display: none;
      }

      @media (max-width: 480px) {
        display: none;
      }
    }

    &-image {
      display: block;

      @media (min-width: 481px) and (max-width: 713px) {
        height: 30px;
      }

      @media (max-width: 480px) {
        height: 30px;
        justify-self: center;
      }
    }

    &-text {
      font-size: var(--thick-font-size);
      padding-top: 5px;
      text-transform: uppercase;

      @media (min-width: 481px) and (max-width: 713px) {
        display: none;
      }

      @media (max-width: 480px) {
        display: none;
      }
    }
  }

  &__search {
    grid-area: search;

    @media (max-width: 713px) {
      display: none;
    }
  }

  &__navigation {
    grid-area: menu;
    border-top: var(--border-thin-width) solid var(--grey);
    padding: var(--small-gap) 0;
    position: relative;
    min-width: 0;
    font-style: italic;
    background-color: var(--white);

    @media (min-width: 481px) and (max-width: 713px) {
      padding: var(--minimum-gap) 0;
    }

    @media (max-width: 480px) {
      padding: 0;
      border: none;
    }

    &-prev,
    &-next {
      background-image: url(../../assets/images/arrow-yellow-white.svg), linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
      background-size: contain;
      background-repeat: no-repeat;
      width: 50px;
      height: 55px;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
      cursor: pointer;
      transform: translateY(-50%);

      @media (max-width: 480px) {
        background-image: url(../../assets/images/arrow-yellow-button.svg), linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
        width: 30px;
        height: 100%;
        outline-color: transparent;
        transform: translateY(-50%) rotate(180deg);
        right: 0;
      }

      @media (min-width: 481px) and (max-width: 713px) {
        background-image: url(../../assets/images/arrow-yellow-button.svg), linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
        width: 30px;
        height: 100%;
        outline-color: transparent;
        transform: translateY(-50%) rotate(180deg);
        right: 0;
      }
    }

    &-next {
      left: auto;
      right: 0;
      transform: translateY(-50%) rotate(180deg);

      @media (min-width: 480px) and (max-width: 713px) {
        outline-color: transparent;
        transform: translateY(-50%);
      }

      @media (max-width: 480px) {
        outline-color: transparent;
        transform: translateY(-50%);
      }
    }
  }

  &__category-link {
    display: grid;
    font-family: var(--accent-font-family);
    font-size: var(--accent-size);
    background-color: var(--dark-overlay);
    color: var(--dark);
    border-radius: var(--border-radius);
    padding: 0 var(--mid-gap);
    white-space: nowrap;
    line-height: var(--big-gap);
    width: min-content;

    @media (min-width: 481px) and (max-width: 713px) {
      font-size: var(--mobile-categoty-nav-font-size);
      line-height: 45px;
      border-radius: 8px;
    }

    @media (max-width: 480px) {
      font-size: var(--mobile-categoty-nav-font-size);
      line-height: 40px;
      border-radius: 8px;
    }

    &.selected,
    &:hover {
      background-color: var(--orange);
      color: var(--white);
    }
  }

  &__indicator {
    position: absolute;
    right: 17px;
    top: -1px;
    background-color: var(--dark-orange);
    border-radius: 50%;
    width: 17px;
    height: 17px;
    font-weight: 900;
    font-size: 12px;
    line-height: 17px;
    color: var(--white);

    @media (max-width: 480px) {
      right: 25px;
      top: -1px;
      font-size: 12px;
      line-height: 15px;
    }

    @media (min-width: 481px) and (max-width: 713px) {
      right: 22px;
    }

    @media (min-width: 714px) and (max-width: 885px) {
      right: 17px;
    }
  }

  &__mobile-search {
    display: none;

    @media (max-width: 713px) {
      display: block;
    }
  }
}
