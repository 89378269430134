.pointer {
  cursor: pointer;
}

.no-scroll {
  overflow: hidden;
}

.dimmer {
  z-index: 1;
  position: absolute;
  top: 0!important;
  left: 0!important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(255,255,255,0.7);

  display: flex;
  align-items: center;
  justify-content: center;
}

.relative {
  position: relative;
}

.no-margin {
  margin: unset;
}

.absolute {
  position: absolute;
}
