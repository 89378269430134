.coupon {
  background-color: var(--white);
  border-radius: var(--border-medium-radius);
  padding: var(--minimum-gap) var(--small-gap);
  text-align: center;

  .faded {
    opacity: 0.3;
  }

  p,
  h2 {
    font-family: var(--alter-font-family);
  }

  hr {
    height: 1px;
    border: none;
    background-color: var(--light-grey);
  }

  p {
    font-size: var(--big-font-size);
    font-weight: 900;

    &:not(:last-of-type) {
      margin: 0 0 var(--big-small-gap);
    }

    &:last-of-type {
      margin: 0;
    }

    b {
      font-weight: normal;
      color: var(--light-blue);
    }
  }

  &__accent {
    font-size: var(--h3-size) !important;
    color: var(--green);

    &_big {
      font-size: var(--h1-size) !important;
    }

    &.error {
      color: var(--dark-grey)
    }
  }

  h2 {
    color: var(--orange);
    font-family: var(--accent-font-family);
    font-size: var(--mid-accent-size);
    font-weight: normal;
    font-style: italic;
    text-transform: capitalize;
  }
}
