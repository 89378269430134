.breadcrumbs {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 0px 20px;

  &__item {
    color: var(--light-blue);
    font-size: var(--minimum-font-size);
    font-weight: 400;
    margin-right: 8px;

    &--link {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__divider {
    margin-right: 4px;
    width: 16px;
    height: 16px;
    background-image: url('../../../assets/images/icons/arrow-right-green.svg');
    background-position: center center;
    background-repeat: no-repeat;
  }

  @media (max-width: 713px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
