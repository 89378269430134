.order {
  display: flex;
  flex-direction: column;
  font-weight: 300;

  .cart {
    height: fit-content;
    font-weight: 300;

    &__item {
      display: grid;
      grid-template-columns: 60px 1fr 1fr;
      grid-gap: var(--small-mid-gap);
      padding: var(--small-gap);
      margin: 0;

      &-properties {
        grid-column: 2 / 4;
      }

      &-image {
        display: grid;
        grid-row: 1 / 3;
        justify-items: center;
        align-items: center;
      }

      &-title {
        color: var(--light-blue);
        font-size: var(--big-mid-font-size);
      }

      &-amount,
      &-total {
        color: var(--grey);
        font-size: var(--big-font-size);
        align-self: end;
      }

      &-amount {
        justify-self: left;
      }

      &-free {
        text-decoration: line-through;

        &:after {
          display: inline-block;
          content: 'For free!';
          text-decoration: none;
          color: var(--light-blue);
          margin-left: var(--small-gap);
          font-family: var(--accent-font-family);
          font-size: var(--mid-accent-size);
          font-weight: normal;
        }
      }

      &-total {
        white-space: nowrap;
        justify-self: right;
      }
    }

    & img {
      max-height: 100px;
    }
  }

  .money-buttons {
    display: flex;
    justify-content: space-between;
    max-width: 384px;
    flex-direction: row-reverse;

    button {
      border: 1px solid var(--slightly-grey);
      border-radius: 8px;
      color: #0D253E;
      font-size: var(--h3-size);
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;

      &.active {
        background-color: #3E6D2E;
        color: white;
      }

      &.clear {
        background-color: var(--slightly-grey)
      }
    }

    @media (max-width: 480px) {
      button {
        width: 52px;
        height: 52px;
        font-size: var(--h4-size);
      }
    }
  }

  .secondary__cancel {
    background-image: url('../../assets/images/cancel-money.svg');
    background-repeat: no-repeat;
    background-position: center
  }

  .money-container {
    position: relative;
    width: 100%;
    max-width: 384px;
    border-radius: var(--border-radius);
    height: 48px;
    border: var(--border-thin-width) solid var(--light-grey);

    .loader {
      all: unset
    }

    &.bonus {
      input {
        margin-left: 73px;
        padding-left: unset;
      }
    }

    .input-prepend {
      height: 100%;
      position: absolute;
      z-index: 1;
      vertical-align: baseline;
      font-size: var(--h3-size);
      margin-top: 10px;
      margin-left: 60px;
      padding-top: 3px;

      &.driver {
        margin-left: 10px;
        color: var(--black);
      }

      @media (max-width: 713px) {
        margin-top: 5px;
        padding-top: 7px;
        font-size: var(--mobile-form2-font-size)
      }

      @media (max-width: 480px) {
        font-size: var(--big-font-size);
      }
    }

    .image-container {
      position: absolute;
      ;
      margin-left: 17px;
      margin-top: 1px;
      width: 51px;
      float: left;
    }

    .loader {
      height: 50px;
      right: -20px;

      @media (max-width: 713px) {
        z-index: 2;
        right: 40px;
      }

      div {
        position: absolute;
        z-index: 1;
        left: calc(100% + var(--minimum-gap));
        width: 40px;
        height: 40px;

        @media (max-width: 713px) {
          left: auto;
          right: 5px;
        }
      }
    }

    .outer {
      left: calc(100% + var(--minimum-gap));

      &.check {
        position: absolute;
        top: var(--minimum-gap);
        --size: 40px;
        height: var(--size);
        width: var(--size);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media (max-width: 713px) {
        right: 10px;
        left: auto;
      }
    }

    input {
      position: absolute;
      z-index: 0;
      width: 80%;
      height: 38px;
      margin: 0;
      margin-top: 4px;
      float: left;
      border: none;
      outline: none;
      padding-left: 73px;

      @media (max-width: 713px) {
        margin-top: 5px;
      }
    }

    .tips {
      padding-left: 30px;
    }

    button {
      float: right;
      border-radius: 0 8px 8px 0;
      margin-top: 0;
      margin-bottom: 2px;
      color: var(--white);
      font-size: var(--h3-size);
      background-color: var(--light-blue);
      width: 90px;
      height: 49px;
    }

    .secondary {
      display: block;
      width: 50px;
      height: 35px;
      border-radius: 5px;
      background-color: #cfcfcf;
      margin-right: 5px;
      margin-top: 6px;
      font-size: 20px;

      &.all {
        margin-right: 11px;
      }

      @media (max-width: 1024px) {
        display: none
      }

    }
  }

  .checkout {
    margin-bottom: 0;
    padding-bottom: var(--small-gap);
    border-bottom: none;
    display: block;

    &-option {
      color: var(--grey);
      font-size: var(--big-font-size);
      margin-bottom: var(--small-gap);

      &__value {}

      &--total {
        color: var(--dark-grey);
        font-size: var(--h3-size);
        margin: 0;
      }
    }
  }

  .error {
    font-weight: 900;
    font-size: var(--big-mid-font-size);
  }
}

.order-form {
  counter-reset: order-step-number;
  padding: var(--mid-gap) 0 0 var(--big-gap);

  label {
    color: var(--orange);
  }

  &.form-type-2 {
    .field.dropdown .second-button {
      color: var(--dark);
    }

    .order-form {
      &__step {
        &.payment {
          label {
            color: var(--orange);
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          label {
            display: block;
            margin-top: 7px;
          }

          .error {
            font-weight: 900;
            font-size: var(--big-mid-font-size);
            color: var(--red);
          }
        }
      }
    }
  }

  padding: var(--mid-gap) 0 0 60px;

  .braintree-upper-container {
    z-index: 1;
  }

  @media (max-width: 713px) {
    padding: var(--small-gap);

    &.form-type-2 {

      .field,
      input,
      textarea {
        font-size: var(--big-font-size);
      }
    }
  }

  button.order-form__button {
    display: block;
    width: 100%;
    background-color: #3E6D2E;
    color: var(--white);
    font-size: var(--accent-size);
    padding: var(--small-gap);
    border-radius: var(--border-radius);
    border: none;
    cursor: pointer;

    &--error {
      background-color: #a0a0a0 !important;
    }
  }
}
