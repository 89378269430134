.checkout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "extra results";
  grid-gap: var(--mid-gap);
  align-items: end;
  white-space: nowrap;
  padding: 0 0 var(--big-mid-gap);
  margin-top: var(--mid-gap);
  border-bottom: var(--border-thin-width) solid var(--grey);

  &--with-extra {
    grid-template-columns: 1fr minmax(50%, 2fr);
  }

  &--single {
    grid-template-columns: 1fr;
    grid-template-areas: none;
    border-bottom: none;
    display: block;

    .checkout-option--total {
      grid-template-columns: auto auto;
      grid-gap: var(--small-gap);
      font-size: var(--h3-size);
      margin: 0;
      justify-self: flex-start;
    }
  }

  &__extra {
    grid-area: extra;
  }

  &__options {
    grid-area: results;

    .form__input {
      width: 100%;
    }

    .checkout--single & {
      grid-area: initial;
      display: grid;
      grid-template-columns: 3fr 2fr;
      grid-gap: var(--small-gap);
      align-items: center;
    }
  }

  &-option {
    display: grid;
    grid-gap: var(--big-mid-gap);
    grid-template-columns: 1fr 1fr;
    margin-bottom: var(--small-mid-gap);
    text-transform: uppercase;
    font-size: var(--accent-size);
    color: var(--dark-grey);

    &--total {
      font-weight: 400;
      font-size: var(--big-accent-size);
      color: var(--dark);
      margin: var(--big-mid-gap) 0;
    }

    &__label {
      text-align: left;
      display: flex;
      gap: 7px;
      padding: 2px 0;
    }

    &__value {
      text-align: right;
    }

    &__icon {
      height: 24px;
    }

    &__tooltip {
      max-width: 150px;
      text-transform: none;
      word-break: break-word;
      z-index: 10000;
      text-wrap: wrap;
    }

  }

  @media (max-width: 713px) {
    margin: var(--mid-gap) 0 10px;
    padding: 0;
    border-bottom: none;
    grid-gap: 0;

    &--with-extra {
      grid-template-columns: 1fr;
      grid-template-areas:
        "results"
        "extra";
    }

    &-option {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: var(--mobile-default-font-size);
      color: var(--dark-grey);

      &--total {
        font-weight: 400;
        font-size: var(--big-font-size);
        color: var(--dark);
        margin: 10px 0;
      }
    }
  }

  @media (max-width: 480px) {
    .checkout__options {
      grid-template-columns: 3fr 2fr;
      grid-template-areas:
        "option-quantity option-price"
        "option-total option-total"
        "add-button add-button";


      .form__counter {
        grid-area: option-quantity;
      }

      .option-price {
        grid-area: option-price;
      }

      .checkout-option--total {
        grid-area: option-total;
      }

      .form__button {
        grid-area: add-button;
      }
    }
  }
}
